import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_GET_CABLE_INTERNET_PLANS_PRICING',
  request: (id) => request.get(`/residents/personal-tasks/${id}/cable-internet-provider-services`)
    .then(r => r.data.data.cable_internet_provider_services),
  selector: (state, id) => state?.cableInternetPlansPricing?.[id],
  reducers: {
    success: (state, { response, params: [id] }) => {
      return {
        ...state,
        cableInternetPlansPricing: {
          ...state.cableInternetPlansPricing,
          [id]: response,
        },
      }
    }
  }
};

export const {
  action: getCableInternetPlansPricing,
  reducer,
  useResource: useCableInternetPlansPricing,
  usePending: useCableInternetPlansPricingPending,
} = generate.redux(creator);

export const initialState = {
  cableInternetPlansPricing: {},
};
