import { generate, request } from '@moved/services';

const creator = {
  namespace: 'PERSONAL_TASKS_CABLE_INTERNET_GET_PROVIDER_SERVICE_LINK',
  request: (id, serviceId) => request.post(`/residents/personal-tasks/${id}/cable-internet-provider-services/${serviceId}/link`,{})
    .then(r => r.data.data?.cable_internet_provider_link?.url),
};

export const {
  action: getProviderServiceLink,
  reducer,
  usePending: useProviderServiceLinkPending,
} = generate.redux(creator);
