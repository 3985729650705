import { generate, request } from '@moved/services';

const creator = {
  namespace: 'USER_CLAIM_EXTERNAL_ACCOUNT',
  request: (data={}) => request.post(`/residents/users/external-claim`, data, {withCredentials:true})
    .then(r => r.data.data),
}

export const {
  reducer,
  action: claimExternalAccount,
  usePending: useClaimExternalAccountPending,
} = generate.redux(creator);

export const initialState = {};
