import * as cableInternet from './cableInternet/actions/reducer';
import * as purchaseRentersInsurance from './purchaseRentersInsurance/actions/reducer';

export const reducers = [
  ...cableInternet.reducers,
  ...purchaseRentersInsurance.reducers,
];

export const initialState = {
  ...cableInternet.initialState,
  ...purchaseRentersInsurance.initialState,
};
