import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useNotify, userService } from '@moved/services';
import { LayoutBrandedPromo } from '@moved/product';

import { setPassword } from '../actions';
import { useSetPasswordPending } from '../actions/selectors';
import { SetPasswordForm } from './SetPasswordForm';

export const PasswordSet = (props) => {
  const { token } = useParams();
  const history = useHistory();
  const notify = useNotify();
  const pending = useSetPasswordPending();

  const onComplete = ({ user }) => {
    notify.default(`Successfully set your password. Welcome, ${user.firstname}!`);
    userService.redirect(user, history);
  };

  return (
    <LayoutBrandedPromo>
      <Helmet>
        <title>Set your password : Moved</title>
      </Helmet>
      <SetPasswordForm
        title='Set Your Password'
        subtitle={`You'll use this to sign in and access a dashboard of your upcoming move(s)!`}
        cta='Save Password'
        token={token}
        pending={pending}
        onSubmit={setPassword}
        onComplete={onComplete}
      />
    </LayoutBrandedPromo>
  );
}
