import { generate, request } from '@moved/services';

const creator = {
  namespace: 'USER_SET_PASSWORD',
  request: (data={}) => request.post(`/users/password`, data, {withCredentials:true})
    .then(r => r.data.data),
};

export const {
  reducer,
  action: setPassword,
  usePending: useSetPasswordPending,
} = generate.redux(creator);

export const initialState = {};
