import { CableInternetDrawer } from './CableInternetDrawer';

export const cableInternet = {
  TaskDrawer: CableInternetDrawer,
  options: {
    fullWidth: true,
    requirements: [
      {
        name: 'destination_address',
        message: 'Destination address is required to continue',
      }
    ],
  },
}
