import React from 'react';
import { useParams, Link } from 'react-router-dom';
import classNames from 'classnames';

import { ProgressRing, Card } from '@moved/ui';

import { useMove } from '../../moves/actions/selectors';

import CSS from './styles/MoveStepNavigation.module.scss';

export const MoveStepNavigation = () => {
  const { moveId, stepId } = useParams();
  const { move_steps: moveSteps } = useMove(moveId);

  if(moveSteps?.length < 2) return null;
  // if same building move, promote the unit instead of the building name
  const isSameBuildingMove = moveSteps.every(step => step.building?.id === moveSteps[0].building?.id);

  return (
    <div className={CSS.container}>
      <Card className={classNames('stackHorizontal gap-8',CSS.moveStepsHolder)}>
        { moveSteps.map(moveStep => (
          <Link
            to={`/moves/${moveId}/steps/${moveStep.id}`}
            className={classNames(CSS.stepLink,{[CSS.active]:moveStep.id === parseInt(stepId)})}
            key={moveStep.id}
          >
            { moveStep.building?.dashboard_image_url && (
              <div className={CSS.thumbnail} style={{
                backgroundImage: `url('${moveStep.building?.dashboard_image_url}')`
              }} />
            )}
            <div className={CSS.column}>
              <div className='labelXS contentSecondary'>
                { moveStep.display_name }
              </div>
              <div className='headingL contentPrimary textTruncate'>
                  { isSameBuildingMove && moveStep.lease?.address?.unit ? (
                    `Unit ${ moveStep.lease?.address?.unit }`
                  ) : (
                    moveStep.building?.settings?.display_name ?? moveStep.building?.name
                  )}
              </div>
              { moveStep.lease?.address?.unit && (
                <div className='labelS contentSecondary'>
                  { isSameBuildingMove ? (
                    moveStep.building?.settings?.display_name ?? moveStep.building?.name
                  ) : (
                    `Unit ${ moveStep.lease?.address?.unit }`
                  )}
                </div>
              )}
            </div>
            <div className='flex-none labelS contentPrimary'>
              <ProgressRing
                progress={(moveStep.move_tasks ?? []).filter(task => task.requirement === 'required' && task.status === 'complete').length}
                total={(moveStep.move_tasks ?? []).filter(task => task.requirement === 'required').length}
              />
            </div>
          </Link>
        ))}
      </Card>
    </div>
  );
};
