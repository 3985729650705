import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { TaskFlowScreen } from './TaskFlowScreen';
import { getDashboardRoute } from '../../dashboard/helpers';

import { useActiveBuildingMoveTask } from '../contexts/BuildingMoveTaskContext';
import { ScreenLoading } from '../types/shared';
import { TaskScreenTransition } from './TaskScreenTransition';
import { Train } from './Train';

export const BuildingMoveTaskFlow = () => {
  const { moveId, stepId, taskType, screenSlug, screenContext } = useParams();
  const history = useHistory();
  const {
    activeTaskDefinition,
    activeTaskDetails,
    activeTaskSummary,
    activeTaskBaseRoute,
  } = useActiveBuildingMoveTask();

  const activeMoveStep = activeTaskDefinition.selectors.useActiveMoveStep(stepId);
  const building = activeTaskDefinition.selectors.useBuilding(activeMoveStep?.building?.slug);
  const parsedScreenContext = screenContext && parseInt(screenContext);

  const [flow, setFlow] = useState(activeTaskDefinition.flatFlow);

  const currentIndex = flow.findIndex(screen =>
    screen.slug === screenSlug && (parsedScreenContext == null || screen.context === parsedScreenContext)
  );

  // load the current screen extended with custom building copy
  const currentScreen = currentIndex !== -1 && {
    ...flow[currentIndex],
    ...(building?.copy?.tasks?.[taskType]?.[screenSlug] ?? {})
  };

  // convenience values
  const dashboardRoute = getDashboardRoute(moveId, stepId);

  // keep task flow up-to-date
  useEffect(() => {
    if(activeTaskDetails) activeTaskDefinition.updateFlow(activeTaskDetails);
    setFlow(activeTaskDefinition.flatFlow); // this ensures we rerender on any task details update that changes the flow
  },[activeTaskDetails, activeTaskDefinition, building, taskType]);

  /* handle disallowed screens and no screen specified */
  useEffect(() => {
    // if no task details loaded, we can't determine the screen
    if(!activeTaskDetails) return;
    // check if the requested screen is allowed, if not, redirect to recommended screen
    if(!screenSlug || !activeTaskDefinition.canAccessScreen(activeTaskDetails, screenSlug, parsedScreenContext)) {
      return history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(activeTaskDetails,parsedScreenContext)}`);
    }
  },[activeTaskDetails, activeTaskDefinition, screenSlug, parsedScreenContext]); // eslint-disable-line

  // convenience for navigation
  const close = () => history.push(dashboardRoute);
  // Advance to next screen
  const nextScreen = (data) => {
    if(data) activeTaskDefinition.updateFlow(data);
    const newScreen = activeTaskDefinition.flatFlow[currentIndex + 1];
    // error handle if we somehow got out of bounds
    if(!newScreen) return history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getRecommendedRoute(data??activeTaskDetails,parsedScreenContext)}`);
    // trigger change of screen via history push
    return history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(newScreen,data??activeTaskDetails)}`);
  };
  // Return to previous screen
  const previousScreen = () => {
    const previousScreen = flow[currentIndex - 1];
    if(currentIndex === 0 || !previousScreen) return close();
    if(!activeTaskDefinition.canAccessScreen(activeTaskDetails,previousScreen.slug,previousScreen.context)) return close();
    goToScreen(previousScreen);
  };

  const goToScreen = (screen) => {
    if(!screen || !activeTaskDefinition.canAccessScreen(activeTaskDetails,screen.slug,screen.context)) return;
    history.push(`${activeTaskBaseRoute}${activeTaskDefinition.getScreenRoute(screen,activeTaskDetails)}`);
  };

  if(!currentScreen) return null;

  return (
    <TaskFlowScreen origin={dashboardRoute}>

      <TaskFlowScreen.Sidebar taskDefinition={activeTaskDefinition} origin={dashboardRoute}>
        <Train
          taskDetails={activeTaskDetails}
          taskDefinition={activeTaskDefinition}
          taskBaseRoute={activeTaskBaseRoute}
          activeScreen={currentScreen}
          previousScreen={previousScreen}
          goToScreen={goToScreen}
          onClose={close}
        />
      </TaskFlowScreen.Sidebar>

      <TaskFlowScreen.Content>
        <Helmet>
          <title>
            { `${(currentScreen?.label ?? 'Loading')}${ building ? ` - ${(building?.settings?.display_name ?? '')}` : ''} : Moved` }
          </title>
        </Helmet>
        { currentScreen ? (
          <TaskScreenTransition
            /* needed for the screen transition component */
            screen={currentScreen}
            index={currentIndex}
            /* needed for all screens */
            task={activeTaskDefinition}
            taskDetails={activeTaskDetails}
            taskDefinition={activeTaskDefinition}
            nextScreen={nextScreen}
            previousScreen={previousScreen}
            goToScreen={goToScreen}
            onClose={close}
            origin={dashboardRoute}
            /* needed for personal task flow screens */
            moveId={moveId}
            /* needed for the building move task flow screens */
            taskSummary={activeTaskSummary}
            taskBaseRoute={activeTaskBaseRoute}
          />
        ) : (
          <ScreenLoading />
        )}
      </TaskFlowScreen.Content>

    </TaskFlowScreen>
  );
};
