import { bookMovers } from './bookMovers';
import { cableInternet } from './cableInternet';
import { common } from './common';
import { purchaseRentersInsurance } from './purchaseRentersInsurance';

export const taskTypes = {
  'book-movers': bookMovers,
  'cable-internet': cableInternet,
  'common': common,
  'purchase-renters-insurance': purchaseRentersInsurance,
};
