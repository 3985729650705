import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { groupBy } from 'lodash';

import { format, useNotify, useDrawer, useModal } from '@moved/services';
import { BouncingDots, Button, Drawer, ExpandableCardList, EmptyContent } from '@moved/ui';

import { useMove } from '../../../moves/actions/getMove';
import { EditAddressModal } from '../../modals/EditAddressModal';

import { TaskFlowScreen } from '../../../tasks/components/TaskFlowScreen';
import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
  ScreenLoading,
} from '../../../tasks/types/shared';
import { InternetOfferCard } from '../../../tasks/types/cable-internet/components/InternetOfferCard';

import { getTaskList } from '../../actions/getTaskList';
import { useTask } from '../../actions/getTask';
import { updateTask, useUpdateTaskPending } from '../../actions/updateTask';

import { getCableInternetPlansPricing, useCableInternetPlansPricing, useCableInternetPlansPricingPending } from './actions/getCableInternetPlansPricing';
import { getProviderServiceLink } from './actions/getProviderServiceLink';

import CSS from './styles/CableInternetDrawer.module.scss';

export const CableInternetDrawer = ({ taskId, taskListId, moveId }) => {
  const notify = useNotify();
  const modal = useModal();
  const dispatch = useDispatch();
  const drawer = useDrawer();
  const taskDetails = useTask(taskId);
  const loading = useCableInternetPlansPricingPending();
  const pending = useUpdateTaskPending();

  const move = useMove(moveId);
  const providerServices = useCableInternetPlansPricing(taskId);

  const dashboardRoute = `/moves/${moveId}/personal-tasks`;

  const fetchCableInternetPlans = useCallback(
    (taskId) => dispatch(getCableInternetPlansPricing(taskId))
      .catch(() => notify.error('Error loading cable internet options')),
    [dispatch, notify]
  );

  useEffect(() => {
    if(move?.destination_address == null) {
      window.setTimeout(() => {
        modal.open(
          <EditAddressModal
            name='destination'
            move={move}
            description='We will need your new address to find available service providers in your area.'
          />,
          {
            onClose: (updatedMove) => {
              if(updatedMove?.destination_address == null) drawer.close();
              else fetchCableInternetPlans(taskId);
            },
          },
        );
      }, 400); // slight delay to allow the drawer to open before the modal
    }
    else fetchCableInternetPlans(taskId);
  }, [taskId, fetchCableInternetPlans]); // eslint-disable-line

  // Generates external link when user clicks CTA in InternetOfferCard
  const handleOptionClick = (option) => {
    if (option?.id == null) return;
    dispatch(getProviderServiceLink(taskId, option.id))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  const groupedByProvider = groupBy(providerServices, (d) => d.provider.id);

  const toggleComplete = () => {
    if(pending) return;
    dispatch(updateTask(taskId,{status: taskDetails.status === 'todo' ? 'complete' : 'todo'}))
      .then(() => dispatch(getTaskList(taskListId))) // refresh the list after a task has changed status
      .then(() => drawer.close())
      .catch(err => notify.error(format.error(err)));
  };

  return (
    <Drawer>
      <TaskFlowScreen origin={dashboardRoute}>

        <TaskFlowScreen.Sidebar origin={dashboardRoute}/>

        <TaskFlowScreen.Content>
          { (loading || providerServices == null) ? (
            <ScreenLoading message={<span>Loading local provider options<BouncingDots /></span>} />
          ) : (
            <ScreenLayout>
              <ScreenTitle
                screen={{
                  slug: 'providers',
                  label: 'Select service',
                  title: 'Choose a plan',
                  subtitle: 'Tailor your internet experience by choosing from a range of provider plans below.',
                }}
              />
              <ScreenContent screen={{ maxWidth: '1080px' }}>
                <section className={classNames(CSS.container, 'stackVertical gap-16')}>
                  { Object.entries(groupedByProvider).length === 0 ? (
                    <EmptyContent message='No plans currently available in your area' className='width-half' />
                  ) : (
                    Object.entries(groupedByProvider).map(([providerId, providerOptions]) => (
                      <ExpandableCardList
                        key={providerId}
                        hideCta='Hide plans'
                        showCta='View all plans'
                        cardList={providerOptions.map(option => (
                          <InternetOfferCard
                            key={option.id}
                            internetOffer={option}
                            onClick={handleOptionClick}
                          />
                        ))}
                      />
                    ))
                  )}
                </section>
              </ScreenContent>
              <ScreenActions>
                <Button
                  text='Close'
                  color='secondary'
                  onClick={() => drawer.close()}
                  disabled={pending}
                />
                { taskDetails?.status === 'todo' ? (
                  <Button
                    text='Mark complete'
                    color='primary'
                    icon={{ library: 'code', symbol: 'Done' }}
                    onClick={toggleComplete}
                    disabled={pending}
                  />
                ) : (
                  <Button
                    text='Mark incomplete'
                    color='tertiary'
                    showWarning={true}
                    icon={{ library: 'code', symbol: 'Done' }}
                    onClick={toggleComplete}
                    disabled={pending}
                  />
                )}
              </ScreenActions>
            </ScreenLayout>
          )}
        </TaskFlowScreen.Content>
      </TaskFlowScreen>
    </Drawer>
  );
};
