import React from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { useNotify, format } from '@moved/services';
import { Button, CompleteCard, FieldList } from '@moved/ui';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  CompleteScreenActions
} from '../../shared';
import { Snippet } from '../../../../snippets';

import taskCSS from '../../../components/styles/TaskFlow.module.scss';

const statusMap = {
  'not-started': {
    status: 'pending',
    title: 'Not Started',
    flavor: 'Once your profile is completed and verified by PetScreening, this task will update automatically within 2 hours.',
    button_cta: 'Continue PetScreening Profile',
  },
  'draft': {
    status: 'pending',
    title: 'In Progress',
    flavor: 'Property management has been notified that you have started the screening process. Once your profile is completed and verified by PetScreening, this task will update automatically within 2 hours.',
    button_cta: 'Continue PetScreening Profile',
  },
  'in-review': {
    status: 'pending',
    title: 'In Review',
    flavor: 'Property management has been notified that you have submitted your screening profile. Once your profile is verified by PetScreening, this task will update automatically within 2 hours.',
    button_cta: 'View PetScreening Profile',
  },
  'action-required': {
    status: 'rejected',
    title: 'Action Required',
    flavor: "PetScreening has reviewed your profile and requires you to take action. Click 'Update PetScreening Profile' below to complete this task.",
    button_cta: 'Update PetScreening Profile',
  },
  'complete': {
    status: 'approved',
    title: 'Complete',
    flavor: 'Property management has been notified of your successfully completed PetScreening profile.',
    button_cta: 'View/Edit PetScreening Profile',
  },
  'expired': {
    status: 'cancelled',
    title: 'Expired',
    flavor: "Your PetScreening profile has expired. Please click 'Update PetScreening Profile' below to complete this task.",
    button_cta: 'Update PetScreening Profile',
  },
}

export const Summary = ({ screen, taskDefinition, taskDetails, taskSummary, moveId, origin, previousScreen, onClose }) => {
  // HOOKS
  const dispatch = useDispatch();
  const notify = useNotify();

  const { actions, helpers, slug: taskType } = taskDefinition;

  const overrides = helpers.getOverrides(taskSummary) || [];
  const profile = overrides.length > 0 ? overrides[0].petscreening_profile : helpers.getActiveRequest(taskDetails);

  const {
    status,
    title,
    flavor,
    button_cta,
  } = statusMap[profile.status] || {};

  const linkToPetscreening = () => {
    dispatch(actions.click(get(profile,'id')))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  const attributes = [
    ...helpers.getRequestFields(profile),
    {
      value: (
        <div className={taskCSS.start_over}>
            <Button
              text={button_cta}
              size='small'
              color='tertiary'
              onClick={linkToPetscreening}
            />
          {taskDetails && (
            <Button
              text='Review Instructions'
              size='small'
              color='secondary'
              onClick={() => previousScreen()}
            />
          )}
        </div>
      ),
    },
  ].filter(v=>v);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <CompleteCard
          status={status}
          title={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.title`}>
              {title}
            </Snippet>
          )}
          flavor={(
            <Snippet tag={`tasks.${taskType}.summary.${status}.flavor`}>
              {flavor}
            </Snippet>
          )}
        >
          <FieldList list={attributes} />
        </CompleteCard>
        <CompleteScreenActions origin={origin} moveId={moveId} onClose={onClose} />
      </ScreenContent>
    </ScreenLayout>
)
};
