import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';

import { useUser, useModal } from '@moved/services';
import { DynamicForm } from '@moved/ui';
import { ForgotPasswordModal } from '@moved/product';

import CSS from '../styles/SetPasswordForm.module.scss';

export const SetPasswordForm = ({ title, subtitle, cta='Save Password', token, onSubmit, pending, onComplete}) => {
  const dispatch = useDispatch();
  const modal = useModal();
  const { updateUser } = useUser();
  // state
  const [invalidToken, setInvalidToken] = useState();

  const fields = [
    {label: 'New Password', type: 'password', name: 'password', required: true, value: ''},
    {label: 'Confirm Password', type: 'password', name: 'confirm', required: true, value: ''},
  ];

  const validation = Yup.object({
    confirm: Yup.string()
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          'Passwords don\'t match'
        )
      })
  });

  const openForgotPasswordModal = () => modal.openLegacy(<ForgotPasswordModal />)

  const handleSetPassword = ({ password }) => {
    if(pending) return;
    dispatch(onSubmit({ password, token }))
      .then(r => {
        const { user, ...tokenData } = r?.login_response ?? r; // support two different api response envelopes
        updateUser(user, tokenData);
        onComplete?.(r);
      })
      .catch(() => setInvalidToken(true));
  };

  return (
    <div className={CSS.form_wrapper}>
      { invalidToken ? (
        <>
          <h1 className={CSS.title}>Sorry</h1>
          <h3 className={CSS.subtitle}>For your security this password link has expired. You may try again by requesting a new link.</h3>
          <button
            type="button"
            className={'btn-primary'}
            onClick={openForgotPasswordModal}>Request a new link</button>
        </>
      ) : (
        <>
          { title && <h1 className={CSS.title}>{title}</h1> }
          { subtitle && <h3 className={CSS.subtitle}>{subtitle}</h3> }
          <DynamicForm
            id="password-form"
            fields={fields}
            validation={validation}
            onSubmit={handleSetPassword}
            disabled={pending}
            formStyle='underline'
          />
          <label
            htmlFor="password-form-submit"
            tabIndex="0"
            role="button"
            className={classNames('btn-primary',CSS.btn_set_password,{loading:pending})}
            disabled={pending}
          >
            { cta }
          </label>
        </>
      )}
    </div>
  );
}
