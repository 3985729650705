import { generate, request } from '@moved/services';
import { get } from 'lodash';

const creator = {
  namespace: 'LANDING_PAGES_GET_LANDING_PAGE',
  request: (slug) => request.get(`/residents/landing-pages/${slug}`).then(r => get(r,'data.data.landing_page')),
  selector: (state, slug) => get(state,`landingPages.${slug}`),
  reducers: {
    success: (state, { response: landingPage, params: [slug] }) => ({
      ...state,
      landingPages: {
        ...state.landingPages,
        [slug]: landingPage,
      },
    }),
    failure: (state, { params: [slug] }) => ({
      ...state,
      landingPages: {
        ...state.landingPages,
        [slug]: null,
      },
    })
  }
}

export const {
  action,
  reducer,
  useResource: useLandingPage,
  usePending: useLandingPagePending,
} = generate.redux(creator);

export const initialState = {
  landingPages: {},
};
