import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import { LoaderOverlay, Transitions, Greeting } from '@moved/ui';
import { useUser, useTrack } from '@moved/services';

import { LayoutSidebar, SplashBG } from '../../common';
import { useBuilding, useBuildingPending } from '../../common/actions/selectors';
import { useMove } from '../../moves/actions/selectors';

import { useActiveMoveStep } from '../contexts';
import { MoveStepNavigation } from './MoveStepNavigation';
import { LeaseData } from './LeaseData';
import { TaskList } from './TaskList';
import { PersonalTasksLeader } from './PersonalTasksLeader';
import CSS from './styles/Dashboard.module.scss';

const Intro = ({ moveStep, user }) => {
  if(moveStep.is_for_transfer) return <Greeting user={user} className={CSS.greeting} />;
  return (
    <h1 className={CSS.greeting}>
      {`${moveStep.type === 'move-in' ? 'Welcome' : 'Goodbye for now'}${get(user,'firstname') ? ', '+user.firstname : ''}!`}
    </h1>
  );
};

export const Dashboard = () => {
  const { user, isAuthenticated } = useUser();
  const Track = useTrack();
  const { moveId, stepId } = useParams();

  // state
  const [ tasks, setTasks ] = useState(false);

  // redux
  const move = useMove(moveId);
  const activeMoveStep = useActiveMoveStep();
  const building = useBuilding(get(activeMoveStep,'building.slug'));
  const buildingPending = useBuildingPending();

  // event tracking
  useEffect(() => {
    if(isAuthenticated) {
      if(!Cookies.get('lastViewedDashboard')) Track.event('Dashboard');
      Cookies.set('lastViewedDashboard', true, {expires: 2/24}); // the expiration of the cookie is set to 2 hours
    }
  },[isAuthenticated]); // eslint-disable-line

  const _formatTasks = () => {
    let tasks = { required: [], recommended: [] };
    get(activeMoveStep,'move_tasks',[]).forEach(task => {
      if(task.requirement === 'required') tasks.required.push(task);
      else tasks.recommended.push(task);
    });
    setTasks(tasks);
  };

  useEffect(_formatTasks,[activeMoveStep]);

  if(!building) return <LoaderOverlay/>;

  return (
    <LayoutSidebar withParallax>
      <Helmet>
        <title>{`Welcome to ${building ? get(building, 'settings.display_name', '') : 'loading'} : Moved`}</title>
      </Helmet>

      { buildingPending && (<LoaderOverlay/>)}

      <SplashBG background={building.dashboard_image_url}>
        <div>
          <div className={CSS.building_logo}>
            <img src={building.logo_url} alt={building.name} />
          </div>
          <Intro moveStep={activeMoveStep} user={user}/>
        </div>
      </SplashBG>

      <section id="dashboard" className={CSS.dashboard}>

        <MoveStepNavigation />

        <LeaseData activeMoveStep={activeMoveStep} />

        <div className={CSS.transition_wrapper}>
          <Transitions pageKey={stepId} transition={activeMoveStep.type === 'move-out' ? 'slideRight' : 'slideLeft'}>

            <div className={CSS.tasks_section}>
              <div className="container">

                { tasks && tasks.required && (
                  <TaskList
                    title={(
                      get(activeMoveStep,'building_task_list_content.required_tasks_title') ||
                      `Required ${ get(activeMoveStep,'display_name').toLowerCase() } tasks`
                    )}
                    subtitle={(
                      get(activeMoveStep,'building_task_list_content.required_tasks_subtitle') ||
                      `Your building requires you to complete all of these tasks before moving ${ activeMoveStep.type === 'move-out' ? 'out' : 'in' }.`
                    )}
                    message={get(activeMoveStep,'progress_message')}
                    tasks={tasks.required}
                  />
                )}
                { tasks && tasks.recommended && (
                  <TaskList
                    title={(
                      get(activeMoveStep,'building_task_list_content.recommended_tasks_title') ||
                      `Recommended ${ get(activeMoveStep,'display_name').toLowerCase() } tasks`
                    )}
                    subtitle={(
                      get(activeMoveStep,'building_task_list_content.recommended_tasks_subtitle') ||
                      `Optional tasks to help you have a stress free move.`
                    )}
                    tasks={tasks.recommended}
                    progress={false}
                  />
                )}

                <PersonalTasksLeader move={move} />

              </div>
            </div>
          </Transitions>
        </div>
      </section>

    </LayoutSidebar>
  );
}
