import { BouncingDots } from '@moved/ui';

import { TaskFlowScreen } from '../../../tasks/components/TaskFlowScreen';
import { TaskScreenTransition } from '../../../tasks/components/TaskScreenTransition';
import { ScreenLoading } from '../../../tasks/types/shared';

import { PurchaseRentersInsuranceFlowTrain } from './PurchaseRentersInsuranceFlowTrain';

import { ReinInsuranceScreen } from './ReinInsuranceScreen';
import { CompleteInsuranceScreen } from './CompleteInsuranceScreen';

// Main Taskflow Component
export const PurchaseRentersInsuranceFlow = ({
  personalTask,
  purchaseIntent={},
  move,
  dashboardRoute,
}) => {
  const { rein_reference_id: reinId } = purchaseIntent;
  const purchasedPolicy = move.insurance_purchases.find(({ rein_reference_id: id }) => id === reinId);

  const screens = [
    {
      slug: 'rein-insurance',
      component: ReinInsuranceScreen,
      title: 'Purchase renters insurance',
    },
    {
      slug: 'summary',
      component: CompleteInsuranceScreen,
      title: 'Summary',
    },
  ];

  const activeScreenIndex = purchasedPolicy == null ? 0 : 1;

  return (
    <TaskFlowScreen origin={dashboardRoute}>

      <TaskFlowScreen.Sidebar origin={dashboardRoute}>
        <PurchaseRentersInsuranceFlowTrain purchasedPolicy={purchasedPolicy} origin={dashboardRoute} />
      </TaskFlowScreen.Sidebar>

      <TaskFlowScreen.Content>

        { !purchaseIntent?.rein_reference_id ? (
          <ScreenLoading
            message={
              <span>
                Retrieving personalized quotes for your new address<BouncingDots />
              </span>
            }
          />
        ) : (
          <TaskScreenTransition
            screen={screens[activeScreenIndex]}
            index={activeScreenIndex}
            personalTask={personalTask}
            purchaseIntent={purchaseIntent}
            purchasedPolicy={purchasedPolicy}
            move={move}
            origin={dashboardRoute}
          />
        )}

      </TaskFlowScreen.Content>

    </TaskFlowScreen>
  );
};
