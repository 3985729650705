import classNames from 'classnames';
import { groupBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { format, useNotify } from '@moved/services';
import { AtomSpinner, Button, ExpandableCardList } from '@moved/ui';

import { InternetOfferCard } from './InternetOfferCard';

import CSS from './styles/CableInternetProviderServices.module.scss';

import {
  ScreenLayout,
  ScreenTitle,
  ScreenContent,
  ScreenActions,
} from '../../shared';

export const CableInternetProviderServices = ({
  nextScreen,
  screen,
  taskDefinition,
}) => {
  const { id } = useParams();
  const notify = useNotify();
  const dispatch = useDispatch();

  const providerServices = taskDefinition.selectors.useCableInternetProviderServices(id);
  const pending = taskDefinition.selectors.useCableInternetProviderServicesPending();
  const fetch = taskDefinition.actions.getCableInternetProviderServices;
  const submitPending = taskDefinition.selectors.useSubmitCableInternetTaskPending();

  useEffect(() => {
    dispatch(fetch(id))
      .catch(() => notify.error('Error loading cable internet options'))
  }, [id, dispatch, fetch, notify]);

  const handleSubmit = () => {
    if (submitPending) return;
    dispatch(taskDefinition.actions.submit(id))
      .then(nextScreen)
      .catch(err => notify.error(format.error(err)));
  };

  // Generates external link when user clicks CTA in InternetOfferCard
  const handleProviderClick = (offer) => {
    if (offer?.provider?.id == null) return;
    dispatch(taskDefinition.actions.getProviderLink(id, offer?.provider?.id, 'pricing'))
      .then(link => window.open(decodeURIComponent(link)))
      .catch(err => notify.error(format.error(err)));
  };

  if (pending) {
    return <AtomSpinner />;
  }

  const groupedByProvider = groupBy(providerServices, (d) => d.provider.id);

  return (
    <ScreenLayout>
      <ScreenTitle screen={screen} taskDefinition={taskDefinition} />
      <ScreenContent screen={screen}>
        <section className={classNames(CSS.container, 'stackVertical gap-16')}>
          {Object.entries(groupedByProvider).map(([providerId, providerOptions]) => (
            <ExpandableCardList
              key={providerId}
              hideCta='Hide plans'
              showCta='View all plans'
              cardList={providerOptions.map(provider => (
                <InternetOfferCard
                  key={provider.id}
                  internetOffer={provider}
                  onClick={handleProviderClick}
                />
              ))}
            />
          ))}
        </section>
      </ScreenContent>
      <ScreenActions>
        <Button
          size='default'
          text='Complete'
          onClick={handleSubmit}
        />
      </ScreenActions>
    </ScreenLayout>
  );
};
