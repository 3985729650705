import { merge } from 'lodash';

import * as getCableInternetPlansPricing from "./getCableInternetPlansPricing";
import * as getProviderServiceLink from './getProviderServiceLink';

export const reducers = [
  getCableInternetPlansPricing.reducer,
  getProviderServiceLink.reducer,
];

export const initialState = merge(
  getCableInternetPlansPricing.initialState,
);
