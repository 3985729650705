import { generate, request } from '@moved/services';

const creator = {
  namespace: 'USER_RESET_PASSWORD',
  request: (data={}) => request.post(`/users/password-reset`, data, {withCredentials:true})
    .then(r => r.data.data),
};

export const {
  reducer,
  action: resetPassword,
  usePending: useResetPasswordPending,
} = generate.redux(creator);

export const initialState = {};
